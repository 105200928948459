<script lang="ts" setup>
import { AisRangeInput } from "vue-instantsearch/vue3/es";
/* eslint-disable import/default */
import Slider from "@vueform/slider";
/* eslint-enable import/default */

const props = defineProps<{
  attribute: string;
  attributeName: string;
  isOpen?: boolean;
  dataType: string;
  step: number;
}>();

const config = useRuntimeConfig();
const { t } = useI18n();
const { attribute } = toRefs(props);
const isOpen = props.isOpen ? ref(true) : ref(false);

const mV = (curr: any, range: any, ret = "all") => {
  let min = range.min;
  let max = range.max;
  if (curr.min) min = curr.min;
  if (curr.max) max = curr.max;

  if (props.dataType === "money") {
    if (ret === "min")
      return min.toLocaleString(config.public.locale, {
        style: "currency",
        currency: config.public.currency,
        maximumFractionDigits: 0,
      });
    if (ret === "max")
      return max.toLocaleString(config.public.locale, {
        style: "currency",
        currency: config.public.currency,
        maximumFractionDigits: 0,
      });
  } else if (props.dataType === "weight") {
    const minConverted = min / 1000;
    const maxConverted = max / 1000;
    if (ret === "min") return minConverted + " kg";
    if (ret === "max") return maxConverted + " kg";
  } else if (props.dataType === "capacity") {
    if (ret === "min") return min + " " + t("liter");
    if (ret === "max") return max + " " + t("liter");
  } else if (props.dataType === "distance") {
    const formattedMin = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0, // No decimals
      maximumFractionDigits: 0, // No decimals
      useGrouping: true, // Enable grouping (thousands separator)
    })
      .format(min)
      .replace(/,/g, " "); // Replace comma with space

    const formattedMax = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0, // No decimals
      maximumFractionDigits: 0, // No decimals
      useGrouping: true, // Enable grouping (thousands separator)
    })
      .format(max)
      .replace(/,/g, " "); // Replace comma with space

    if (ret === "min") return formattedMin + " m";
    if (ret === "max") return formattedMax + " m";
  } else {
    if (ret === "min") return min;
    if (ret === "max") return max;
  }

  return [min, max];
};

const tooltipFormat = (val: any) => {
  if (props.dataType === "money") {
    return val.toLocaleString(config.public.locale, {
      style: "currency",
      currency: config.public.currency,
      maximumFractionDigits: 0,
    });
  } else if (props.dataType === "weight") {
    const converted = val / 1000;
    return converted.toFixed(2) + " kg";
  } else if (props.dataType === "capacity") {
    return val + " liter";
  } else if (props.dataType === "distance") {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0, // No decimals
      maximumFractionDigits: 0, // No decimals
      useGrouping: true, // Enable grouping (thousands separator)
    })
      .format(val)
      .replace(/,/g, " "); // Replace comma with space
    return formattedNumber + " m";
  } else {
    return val;
  }
};
</script>

<template>
  <AisRangeInput :attribute="attribute">
    <template #default="{ currentRefinement, range, refine }">
      <div v-if="range">
        <div
          v-if="range.max > range.min"
          class="bg-white md:bg-brand-surface rounded-16 p-4 text-15"
        >
          <div
            class="flex flex-row cursor-pointer"
            @mousedown.prevent="isOpen = !isOpen"
          >
            <div class="basis-5/6 font-bold text-xl flex flex-row font-lora">
              <span>{{ attributeName }}</span>
            </div>
            <div class="basis-1/6 text-right">
              <ProductListFilterArrow :inverted="isOpen" />
            </div>
          </div>
          <div class="pt-5" :class="{ hidden: !isOpen }">
            <Slider
              :min="range.min"
              :max="range.max"
              :model-value="mV(currentRefinement, range)"
              :step="props.step"
              :tooltips="true"
              show-tooltip="drag"
              :format="tooltipFormat"
              @change="refine({ min: $event[0], max: $event[1] })"
            />
            <div class="mt-5 grid grid-cols-12">
              <div class="col-span-5 bg-white text-center rounded-2xl">
                {{ mV(currentRefinement, range, "min") }}
              </div>
              <div class="col-span-2 text-center">-</div>
              <div class="col-span-5 bg-white text-center rounded-2xl">
                {{ mV(currentRefinement, range, "max") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AisRangeInput>
</template>

<style src="@vueform/slider/themes/default.css"></style>
